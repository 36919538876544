<template>
  <div class="header">
    <div class="container">
      <div class="header__body">
        <div class="header__logo">
          <a href="/">
            <picture>
              <source srcset="/img/logo.svg" type="image/webp" />
              <img src="/img/logo.svg" alt="logo" />
            </picture>
          </a>
        </div>
        <nav
          class="header__menu"
          :class="{
            active: burger_active,
          }"
        >
          <ul class="header__list">
            <li>
              <a href="#" class="header__link link">Формы</a>
            </li>
            <li>
              <router-link to="/marketplace" class="header__link"
                >Маркетплейс</router-link
              >
            </li>
            <li>
              <a href="#" class="header__link">Форум</a>
            </li>
          </ul>
        </nav>
        <div class="header__buttons">
          <a href="#">
            <picture>
              <source srcset="/img/Settings/bell.svg" type="image/webp" />
              <img src="/img/Settings/bell.svg" alt="bell" />
            </picture>
          </a>
          <a href="#">
            <picture>
              <source srcset="/img/Settings/message.svg" type="image/webp" />
              <img src="/img/Settings/message.svg" alt="message" />
            </picture>
          </a>
          <a href="#">
            <picture>
              <source
                srcset="/img/Settings/avatar-mini.webp"
                type="image/webp"
              />
              <img src="/img/Settings/avatar-mini.png" alt="avatar" />
            </picture>
          </a>
        </div>
        <div
          class="header__burger"
          :class="{
            active: burger_active,
          }"
          @click="toggleBurger"
        >
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthHeader",
  data() {
    return {
      burger_active: false,
    };
  },
  methods: {
    toggleBurger() {
      this.burger_active = !this.burger_active;
      document.querySelector("body").classList.toggle("lock");
    },
  },
};
</script>