<template>
  <div class="wrapper blue-circles">
    <AuthHeader />
    <main class="page">
      <div class="container-1440">
        <div class="team">
          <h1 class="team__title footer-links-title">Команда</h1>
          <div class="team__items">
            <div class="team__item" v-for="item in team" :key="item.id">
              <picture>
                <img :src="item.avatar" :alt="item.name" />
              </picture>
              <h2 class="team__item-name">{{ item.name }}</h2>
              <p class="team__item-major">{{ item.major }}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { API } from "../helpers/api";
import AuthHeader from "../components/headers/Auth.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Team",
  components: {
      AuthHeader,
      Footer
  },
  data() {
    return {
      team: [],
    };
  },
  async mounted() {
    try {
      const response = await API.send("getTeam", {}, "GET");

      if (response.status !== 0) {
        console.error(response);
        alert(response.user_message);
        return;
      }

      this.team = response.team;
    } catch (e) {
      console.error(e);
      alert("Неизвестная ошибка. Попробуйте позже.");
    }
  },
};
</script>

<style>
</style>